import Vue from "vue";
import Vuex from "vuex";

/**
 * --------------------------------------------------------------
 * import module store vuex
 * --------------------------------------------------------------
 */
import customer from "./customer.module";
import staff from "./staff.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    customer,
    staff,
  },
});
