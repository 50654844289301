/**
 * --------------------------------------------------------------
 * for manipulate cookies
 * --------------------------------------------------------------
 */
import { CookiesName, CookiesStaff } from "@/common/config";
import Cookies from "js-cookie";

/**
 * --------------------------------------------------------------
 * for customer cookie
 * --------------------------------------------------------------
 */
export const rwCookies = {
  data() {
    return {
      cookies: {
        /**
         * --------------------------------------------------------------
         * add / update cookies
         * --------------------------------------------------------------
         */
        add: function (data, expired = 7) {
          let rwCookies = Cookies.getJSON(CookiesName);
          if (typeof rwCookies !== "undefined") {
            data = Object.assign(rwCookies, data); // append object
          }
          Cookies.set(CookiesName, data, { expires: expired });
        },

        /**
         * --------------------------------------------------------------
         * remove / destroy cookies
         * --------------------------------------------------------------
         */
        remove: function () {
          Cookies.remove(CookiesName);
        },

        /**
         * --------------------------------------------------------------
         * get all cookies / by index
         * --------------------------------------------------------------
         */
        get: function (index = false) {
          let rwCookies = Cookies.getJSON(CookiesName);
          if (typeof rwCookies !== "undefined") {
            return index && typeof rwCookies[index] !== "undefined"
              ? rwCookies[index]
              : rwCookies;
          }
          return false;
        },
      },
    };
  },
};

/**
 * --------------------------------------------------------------
 * for staff only
 * --------------------------------------------------------------
 */
export const staffCookies = {
  data() {
    return {
      cookies: {
        add: function (data = Object, expired = 7) {
          let rwCookies = Cookies.getJSON(CookiesStaff);
          if (typeof rwCookies !== "undefined") {
            data = Object.assign(rwCookies, data); // append object
          }
          Cookies.set(CookiesStaff, data, { expires: expired });
        },
        get: function (index = false) {
          let rwCookies = Cookies.getJSON(CookiesStaff);
          if (typeof rwCookies !== "undefined") {
            return index && typeof rwCookies[index] !== "undefined"
              ? rwCookies[index]
              : rwCookies;
          }
          return false;
        },
        remove: function () {
          Cookies.remove(CookiesStaff);
        },
      },
    };
  },
};
