/**
 * --------------------------------------------------------------
 * module store vuex for staff init 2021-04-03
 * --------------------------------------------------------------
 */
import { waktu } from "@/common/mixins/mix_helper";
import { staffCookies } from "@/common/mixins/mix_cookies";
import { refMessages } from "@/common/firebase/init";

// action type
export const LOGIN = "staffLogin";
export const LOGOUT = "staffLogout";
export const SEND_MESSAGE = "staffSendMessage";

// mutation type
export const REMOVE_COOKIES = "removeCookies";
// export const ADD_COOKIES = "addCookies";

export default {
  actions: {
    /**
     * --------------------------------------------------------------
     * @param state
     * @param credentials : Object {email, password}
     * --------------------------------------------------------------
     */
    [LOGIN](state, credentials) {
      return new Promise((resolve, reject) => {
        if (
          credentials.email === "addin@rw.com" &&
          credentials.password === "4dd1n"
        ) {
          resolve({
            id: "87a6fankjfna",
            name: "Akhul Syaifudin",
            email: credentials.email,
            departement: "Sales",
            jabatan: "Anak Training",
          });
        } else {
          reject("Incorrect email or password");
        }
      });
    },
    [LOGOUT](state) {
      state.commit(REMOVE_COOKIES);
    },
    /**
     * --------------------------------------------------------------
     * @param state
     * @param params : Object {tipe, message}
     * --------------------------------------------------------------
     */
    [SEND_MESSAGE](state, params) {
      return new Promise((resolve, reject) => {
        try {
          let Cookies = staffCookies.data().cookies.get();
          if (Cookies.isLogin && Cookies.staff.id) {
            let message = {
              id: Cookies.staff.id,
              time: waktu.methods.mixEpoch(),
              name: Cookies.staff.name,
              tipe: params.tipe,
              actor: "staff",
              message: params.message,
            };
            let sendId = refMessages.push(message).key;
            resolve(sendId);
          } else {
            reject("Cookies not found!");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  mutations: {
    [REMOVE_COOKIES]() {
      staffCookies.data().cookies.remove();
    },
  },
};
