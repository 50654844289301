import Vue from "vue";
import VueRouter from "vue-router";
import { staffCookies } from "@/common/mixins/mix_cookies";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "rw-messenger" */ "@/views/BaseChat.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import(/* webpackChunkName:"rw-auth" */ "@/views/Auth"),
  },
  {
    path: "/customer",
    name: "customer",
    component: () =>
      import(/* webpackChunkName:"rw-customer" */ "@/views/Customer"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((url) => url.meta.requiresAuth);
  const staffCookie = staffCookies.data().cookies.get();
  if (
    requiresAuth &&
    (typeof staffCookie.isLogin === "undefined" || !staffCookie.isLogin)
  ) {
    next("/auth");
  } else {
    next();
  }
});

export default router;
