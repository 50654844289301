/**
 * --------------------------------------------------------------
 * pengaturan umum
 * API_URL : for request data
 * CookiesName : cookies for customer
 * CookiesStaff : cookies for staff biar gak crash ketika dibuka 1 browser
 * --------------------------------------------------------------
 */
const API_URL = "localhost";
const CookiesName = "rwCookies";
const CookiesStaff = "rwStaffCookies";

/**
 * --------------------------------------------------------------
 * export variable for other module
 * --------------------------------------------------------------
 */
export { API_URL, CookiesName, CookiesStaff };
