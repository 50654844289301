/**
 * --------------------------------------------------------------
 * time chat or anything
 * mixEpoch : get epoch time
 * --------------------------------------------------------------
 */

export const waktu = {
  methods: {
    mixNow: function () {
      const dt = new Date();
      let minute = (dt.getMinutes() < 10 ? "0" : "") + dt.getMinutes();
      return `${dt.getHours()}:${minute}`;
    },
    mixEpoch: function () {
      return Math.round(Date.now() / 1000);
    },
    mixEpochToHours: function (epochTime) {
      let date = new Date(parseInt(epochTime) * 1000);
      let minutes =
        date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes();
      return date.getHours() + ":" + minutes;
    },
  },
};

/**
 * --------------------------------------------------------------
 * push notify desktop
 * --------------------------------------------------------------
 */
import Push from "push.js";
export const alert = {
  methods: {
    mixAlert: function (name, message) {
      Push.create(name, {
        body: message,
        icon: "./img/rw/customer.png",
        timeout: 4000,
        onClick: function () {
          window.focus();
          this.close();
        },
      });
    },
  },
};
