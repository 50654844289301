/**
 * --------------------------------------------------------------
 * module for customers
 * --------------------------------------------------------------
 */
import { waktu } from "@/common/mixins/mix_helper";
import { rwCookies } from "@/common/mixins/mix_cookies";
import { refCustomer, refMessages } from "@/common/firebase/init";

// action type
export const ISLOGIN = "customerLogin";
export const REGISTER = "customerRegister";
export const SendMessage = "customerSendMessage";

// mutation type
export const SET_LOGIN = "setLogin";

export default {
  state: {
    isLogin: false,
  },
  getters: {
    getIsLogin: function (state) {
      return state.isLogin;
    },
  },
  actions: {
    /**
     * --------------------------------------------------------------
     * @return id customer
     * --------------------------------------------------------------
     */
    [REGISTER](state, payload) {
      return new Promise((resolve, reject) => {
        try {
          let keyID = refCustomer.push(payload).key;
          resolve(keyID);
        } catch (error) {
          reject(error);
        }
      });
    },

    /**
     * --------------------------------------------------------------
     * send message dari customer
     * @param state
     * @param payload : Object {message, tipe (text | image)}
     * @return id message
     * --------------------------------------------------------------
     */
    [SendMessage](state, payload) {
      return new Promise((resolve, reject) => {
        try {
          let Cookies = rwCookies.data().cookies.get();
          if (Cookies.isLogin && Cookies.customer.id) {
            let message = {
              id: Cookies.customer.id,
              time: waktu.methods.mixEpoch(),
              name: Cookies.customer.name,
              tipe: payload.tipe,
              actor: "customer",
              message: payload.message,
            };
            let id = refMessages.push(message).key;
            resolve(id);
          } else {
            reject("Cookies not found!");
          }
        } catch (error) {
          reject(error);
        }
      });
    },

    /**
     * --------------------------------------------------------------
     * @param state
     * @param payload : boolean
     * --------------------------------------------------------------
     */
    [ISLOGIN](state, payload) {
      state.commit(SET_LOGIN, payload);
    },
  },
  mutations: {
    [SET_LOGIN](state, payload) {
      state.isLogin = payload;
    },
  },
};
