import firebase from "firebase";
import "firebase/auth";
import "firebase/database";

/**
 * --------------------------------------------------------------
 * configures app firebase
 * --------------------------------------------------------------
 */
const config = {
  appId: "1:839407455246:web:e6031acd2fda303d87805f",
  apiKey: "AIzaSyBLBx3_Fej3tLs192QlZTCp_T-1MKk3sQ0",
  projectId: "rw-chat-demo",
  authDomain: "rw-chat-demo.firebaseapp.com",
  databaseURL: "https://rw-chat-demo-default-rtdb.firebaseio.com",
  storageBucket: "rw-chat-demo.appspot.com",
  messagingSenderId: "839407455246",
};

/**
 * --------------------------------------------------------------
 * init configures config
 * --------------------------------------------------------------
 */
firebase.initializeApp(config);
const db = firebase.database();
const auth = firebase.auth();

/**
 * --------------------------------------------------------------
 * reference / table firebase
 * --------------------------------------------------------------
 */
const refCustomer = db.ref("customers");
const refMessages = db.ref("messages");

/**
 * --------------------------------------------------------------
 * export const
 * --------------------------------------------------------------
 */
export { db, auth, refCustomer, refMessages };
